import { NotificationManager } from 'react-notifications';

class Cart {
  items = [];
  miniOpen = false;

  constructor() {
    this.items = [];
    this.loadStorage();
  }

  findItem(id) {
    return this.items.find(cartItem => cartItem.id == id);
  }

  addItem(item) {
    this.miniOpen = true;
    const hasItem = this.items.find(cartItem => cartItem.id == item.id);

    if (!hasItem) {
      this.items.push(item);

      this.updateStorage();
    }
  }

  updateItem(item) {
    this.items = this.items.map(cartItem => {
      if (cartItem.id == item.id) {
        return item;
      }
      return cartItem;
    });
    this.updateStorage();
  }

  removeItem(id) {
    this.items = this.items.filter(cartItem => cartItem.id != id);

    this.updateStorage();
  }

  clearCart() {
    this.items = [];

    this.updateStorage();
  }

  getItems() {
    return this.items;
  }

  updateStorage() {
    localStorage.setItem('myPlanCartItems', JSON.stringify(this.items));
  }

  loadStorage() {
    const items = localStorage.getItem('myPlanCartItems')
      ? JSON.parse(localStorage.getItem('myPlanCartItems'))
      : [];

    if (items) {
      this.items = items;
    }
  }
}

const cart = new Cart();

export function useCart() {
  const cartItems = cart.getItems();

  const addItem = item => {
    const cartItem = cart.findItem(item?.id);
    if (!cartItem?.id) {
      cart.addItem(item);
      NotificationManager.success('Ajouté Avec Auccès');
    } else if (false) {
      cart.updateItem({
        ...item,
        quantity: item.quantity + cartItem?.quantity || 0,
      });
    }
  };

  const removeItem = id => {
    cart.removeItem(id);
  };

  const clearCart = () => {
    cart.clearCart();
  };

  const findItem = id => {
    return cart.findItem(id);
  };

  const updateItem = item => {
    return cart.updateItem(item);
  };

  const closeMiniCart = () => {
    cart.miniOpen = false;
  };

  return {
    cartItems,
    addItem,
    findItem,
    updateItem,
    removeItem,
    clearCart,
    closeMiniCart,
    miniOpen: cart.miniOpen,
  };
}
