// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard React components
import SoftBox from '@components/SoftBox';

// Soft UI Dashboard React examples
import Footer from '@examples/Footer';
import DashboardLayout from '@examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '@examples/Navbars/DashboardNavbar';

// Soft UI Dashboard React base styles

// Data

import AffiliatePercentCard from '@examples/Cards/AffiliateCards/AffiliatePercentCard';
import AffiliateStaticCard from '@examples/Cards/AffiliateCards/AffiliateStaticCard';
import AffiliateTopBar from './components/topbar';

import { UserService } from '@core/services';
import KcyVerify from '@layouts/notifications/KcyVerify';
import TeamStructure from '@layouts/team-structure';
import { useAuthStore } from '@store/AuthStore';
import { useReferralStore } from '@store/ReferralStore';
import { useUserStore } from '@store/UserStore';
import _ from 'lodash';
import { useEffect } from 'react';

const Affiliate = () => {
  const referralStore = useReferralStore();
  const userStore = useUserStore();
  const authStore = useAuthStore();
  const user = authStore.user();
  const users = userStore.items;

  const referralTree = UserService.makeItTeamTree(users, user.id);

  const levelOneUsers = referralTree;
  const levelTwoUsers = referralTree.flatMap(user => user.children);
  const totalUsers = levelOneUsers.length + levelTwoUsers.length;

  const levelOneSpending = levelOneUsers.reduce(
    (acc, u) => acc + (u?.totalSpending || 0),
    0
  );
  const levelTwoSpending = levelTwoUsers.reduce(
    (acc, u) => acc + (u?.totalSpending || 0),
    0
  );

  const referralSpending = _.round(levelOneSpending + levelTwoSpending, 3);
  const levelOneCommission = _.round(levelOneSpending * 0.1, 3);
  const levelTwoCommission = _.round(levelTwoSpending * 0.05, 3);

  const totalCommission = _.round(levelOneCommission + levelTwoCommission, 3);

  useEffect(() => {
    userStore.fetchItems();
    referralStore.fetchItems();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <KcyVerify />
      <SoftBox py={3}>
        <AffiliateTopBar />
        <SoftBox mb={3} mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={4}>
              <AffiliateStaticCard
                title={{ text: `${totalCommission} POPOL` }}
                subText='Commission Totale'
                percentage={{ color: 'success', text: '+55%' }}
                icon={{ color: 'info', component: 'money' }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
              <AffiliateStaticCard
                title={{ text: totalUsers }}
                subText="Nombre Dotal D'utilisateurs"
                percentage={{ color: 'success', text: '+55%' }}
                icon={{ color: 'info', component: 'group' }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
              <AffiliateStaticCard
                title={{ text: `$ ${referralSpending}` }}
                subText='Dépenses Des Utilisateurs'
                percentage={{ color: 'success', text: '+55%' }}
                icon={{ color: 'info', component: 'paid' }}
              />
            </Grid>
          </Grid>
        </SoftBox>

        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <AffiliatePercentCard
                title={{ text: 'Commissions De Premier Niveau' }}
                count={`${levelOneCommission} POPOL`}
                percentage={{ color: 'success', text: '10%' }}
                icon={{
                  color: 'info',
                  component: 'timeline',
                }}
                sx={{ textTransform: 'capitalize' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AffiliatePercentCard
                title={{ text: 'Commissions De Deuxième Niveau' }}
                count={`${levelTwoCommission} POPOL`}
                percentage={{ color: 'success', text: '5%' }}
                icon={{ color: 'info', component: 'paid' }}
              />
            </Grid>
          </Grid>
        </SoftBox>

        <TeamStructure referralTree={referralTree} />
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Affiliate;
