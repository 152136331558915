import SoftBox from "@components/SoftBox";
import KycCard from "@examples/kyc/KycCard";
import { useFileStore } from "@store/FileStore";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useFirebase } from "react-redux-firebase";

import { toMoment } from "@core/utils/time";
import { useAuthStore } from "@store/AuthStore";
import { NotificationManager } from "react-notifications";

// import PageHeader from '@/components/base/PageHeader';

const KYC = () => {
  const [activeID, setActiveID] = useState(1);
  const [loading, setLoading] = useState(false);

  const hookForm = useForm();
  const fileStore = useFileStore();
  const firebase = useFirebase();
  const authStore = useAuthStore();
  const user = authStore.user();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
  } = hookForm;

  useEffect(() => {
    if (user?.kyc) {
      Object.keys(user.kyc).forEach((key) => {
        setValue(key, user.kyc[key]);
      });
    }
  }, [user?.id]);

  const stepForword = () => {
    if (activeID < 4) {
      setActiveID(activeID + 1);
    }
  };
  const stepBackword = () => {
    if (activeID > 0) {
      setActiveID(activeID - 1);
    }
  };
  const onSubmit = async (data) => {
    setLoading(true);

    const nicFrontImage =
      (await fileStore.upload(data.nicFrontImage)) ||
      user?.kyc?.nicFrontImage ||
      "";
    const nicBackImage =
      (await fileStore.upload(data.nicBackImage)) ||
      user?.kyc?.nicBackImage ||
      "";
    const nicSelfieImage =
      (await fileStore.upload(data.nicSelfieImage)) ||
      user?.kyc?.nicSelfieImage ||
      "";
    const voterIdImage =
      (await fileStore.upload(data.voterIdImage)) ||
      user?.kyc?.voterIdImage ||
      "";

    await authStore.updateItem({
      ...user,
      status: "pending",
      kyc: {
        nicFrontImage,
        nicBackImage,
        nicSelfieImage,
        voterIdImage,
        name: data.name,
        state: data.state,
        streetAddress: data.streetAddress,
        city: data.city,
        postal: data.postal,
        // walletAddress: data.walletAddress,
        region: data.region,
        dob: data.dob,
        isNic: true,
        isVoterId: data.isVoterId ? true : false,
        status: "pending",
        submitAt: toMoment().unix(),
      },
    });

    await authStore.getItem(user?.id);

    setLoading(false);

    NotificationManager.success("KYC Submitted Successfully", "Success", 3000);

    setActiveID(activeID + 1);
  };

  return (
    <>
      {/* <PageHeader title="KYC Page" text="KYC" /> */}
      <SoftBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
        <KycCard
          activeID={activeID}
          stepForword={stepForword}
          stepBackword={stepBackword}
          hookForm={hookForm}
          loading={loading}
        />
      </SoftBox>
    </>
  );
};
export default KYC;
