

/**
  The boxShadow() function helps you to create a box shadow for an element
 */

// Soft UI Dashboard React helper functions
import pxToRem from "@assets/theme/functions/pxToRem";
import rgba from "@assets/theme/functions/rgba";

function boxShadow(offset = [], radius = [], color, opacity, inset = "") {
  const [x, y] = offset;
  const [blur, spread] = radius;

  return `${inset} ${pxToRem(x)} ${pxToRem(y)} ${pxToRem(blur)} ${pxToRem(
    spread
  )} ${rgba(color, opacity)}`;
}

export default boxShadow;
