import curved9 from '@assets/images/signIn.png';
import SoftBox from '@components/SoftBox';
import SoftButton from '@components/SoftButton';
import SoftInput from '@components/SoftInput';
import SoftTypography from '@components/SoftTypography';
import { ArrowBack } from '@mui/icons-material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CoverLayout from '../components/CoverLayout';

const AddOTP = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate();

  return (
    <>
      <CoverLayout
        ArBack={<ArrowBack />}
        back='Go Back'
        title='Reset Password'
        description='please enter new password'
        image={curved9}
      >
        <SoftBox component='form' role='form'>
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography
                component='label'
                variant='caption'
                fontWeight='bold'
              >
                Enter OTP Please
              </SoftTypography>
            </SoftBox>
            <SoftInput
              type='email'
              placeholder='Enter New Password'
              name='otp'
            />
          </SoftBox>

          <SoftBox mt={4} mb={1}>
            <SoftButton variant='gradient' color='info' fullWidth type='submit'>
              Submit Now
            </SoftButton>
            {error}
          </SoftBox>
        </SoftBox>
      </CoverLayout>
    </>
  );
};

export default AddOTP;
