

// prop-types is a library for typechecking of props
import Icon from '@mui/material/Icon';
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard React components
import SoftBox from '@components/SoftBox';
import SoftTypography from '@components/SoftTypography';

function AffiliatePercentCard({
  bgColor,
  title,
  count,
  percentage,
  direction,
  icon,
}) {
  return (
    <Card sx={{ border: '1px solid #ddd' }}>
      <SoftBox p={2}>
        <Grid container alignItems='center'>
          <Grid item xs={12}>
            <SoftBox
              ml={direction === 'left' ? 2 : 0}
              lineHeight={1}
              justifyContent='center'
              display='flex'
              flexDirection='column'
              alignItems='center'
              minHeight='150px'
              alignContent='center'
            >
              <SoftBox
                variant='gradient'
                bgColor={bgColor === 'white' ? icon.color : 'white'}
                color={bgColor === 'white' ? 'white' : 'dark'}
                width='3rem'
                height='3rem'
                borderRadius='xxl'
                display='flex'
                justifyContent='center'
                alignItems='center'
                shadow='md'
              >
                <Icon fontSize='small' color='inherit'>
                  {icon.component}
                </Icon>
              </SoftBox>
              <SoftTypography
                variant='h2'
                fontWeight='bold'
                justifyContent='start'
                pt={2}
                sx={{ color: '#0842ac', fontSize: '1.875rem' }}
              >
                {count}
              </SoftTypography>
              <SoftTypography
                variant='button'
                fontWeight='bold'
                justifyContent='center'
                color={bgColor === 'white' ? 'dark' : 'white'}
              >
                {title.text}
              </SoftTypography>
              <SoftTypography
                variant='button'
                color={percentage.color}
                fontWeight='bold'
                mt={2}
              >
                {percentage.text}
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of AffiliateStaticCard
AffiliatePercentCard.defaultProps = {
  bgColor: 'white',
  title: {
    fontWeight: 'medium',
    text: '',
    color: '',
  },
  percentage: {
    color: 'success',
    text: '',
    color: '',
  },
  direction: 'right',
};

// Typechecking props for the AffiliateStaticCard
AffiliatePercentCard.propTypes = {
  bgColor: PropTypes.oneOf([
    'white',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(['light', 'regular', 'medium', 'bold']),
    text: PropTypes.string,
  }),
  subText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
      'white',
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
    ]),
    component: PropTypes.node.isRequired,
  }).isRequired,
  direction: PropTypes.oneOf(['right', 'left']),
};

export default AffiliatePercentCard;
