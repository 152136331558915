export const makeItTeamTree = (users, id) => {
  const tree = [];
  const children = users.filter((user) => user.referralId === id);
  children.forEach((child) => {
    const { id, name, email, phone, referralId, totalSpending, createdAt } =
      child;
    tree.push({
      id,
      name,
      email,
      phone,
      referralId,
      totalSpending,
      createdAt,
      children: makeItTeamTree(users, id),
    });
  });
  return tree;
};
