import lodash from "@core/lodash";
import CryptoJS from "crypto-js";

export function decrypt(value) {
  if (!value) return value;
  return CryptoJS.AES.decrypt(value, "marketPlace").toString(CryptoJS.enc.Utf8);
}

export function encrypt(value) {
  if (!value) return value;
  return CryptoJS.AES.encrypt(value, "marketPlace").toString();
}

export function generateOntTimeOtp() {
  return Math.floor(1111 + Math.random() * (9999 - 1111 + 1)).toString();
}

export function uniqueId() {
  return lodash.uniqueId(new Date().getTime().toString());
}
