import { firebaseReducer } from 'react-redux-firebase';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { CartPlanStore } from './CartPlanSlice';
import { MessageStore } from './MessageStore';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  message: MessageStore.reducer,
  cartStoreNew: CartPlanStore.reducer,
});

export const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
