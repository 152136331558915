

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Link from '@mui/material/Link';

// Soft UI Dashboard React components
import SoftBox from '@components/SoftBox';
import SoftTypography from '@components/SoftTypography';

// Soft UI Dashboard React base styles
import typography from '@assets/theme/base/typography';

function Footer({ company, links }) {
  const { href, name } = company;
  const { size } = typography;

  const renderLinks = () =>
    links.map(link => (
      <SoftBox key={link.name} component='li' px={2} lineHeight={1}>
        <Link href={link.href} target='_blank'>
          <SoftTypography variant='button' fontWeight='regular' color='text'>
            {link.name}
          </SoftTypography>
        </Link>
      </SoftBox>
    ));

  return (
    <SoftBox
      width='100%'
      display='flex'
      flexDirection={{ xs: 'column', lg: 'row' }}
      justifyContent='space-between'
      alignItems='center'
      px={1.5}
    ></SoftBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: 'https://www.creative-tim.com/', name: 'Metalogics' },
  links: [
    { href: 'https://metalogics.io/', name: 'Metalogics' },
    { href: 'https://www.creative-tim.com/presentation', name: 'About Us' },
    { href: 'https://www.creative-tim.com/blog', name: 'Blog' },
  ],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
