

// Soft UI Dashboard React components
import SoftBox from '@components/SoftBox';

// Soft UI Dashboard React examples
import DashboardLayout from '@examples/LayoutContainers/DashboardLayout';

// Overview page components
import Header from '@layouts/profile/components/Header';

// Data

// Images

import { useAuthStore } from '@store/AuthStore';
import { useState } from 'react';
import KYC from './components/Kyc/kyc';
import ProfileUpdate from './components/ProfileUpdate';

function Overview() {
  const [profileUpdate, setProfileUpdate] = useState();
  const authStore = useAuthStore();
  const user = authStore.user();
  const onDisplayHandler = () => {
    setProfileUpdate(!profileUpdate);
  };

  return (
    <DashboardLayout>
      <Header onClick={onDisplayHandler} />
      <SoftBox mt={2} mb={3}>
        {user?.status !== 'approved' ? (
          <>
            {!profileUpdate && <KYC />}
            {profileUpdate && <ProfileUpdate />}
          </>
        ) : (
          <>{user?.status == 'approved' && <ProfileUpdate />}</>
        )}
      </SoftBox>

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Overview;
