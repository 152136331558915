import SoftBox from '@components/SoftBox';
import SoftTypography from '@components/SoftTypography';
import { ArrowForward, Info } from '@mui/icons-material';
import { useAuthStore } from '@store/AuthStore';
import { Link } from 'react-router-dom';
import { column } from 'stylis';

const KcyVerify = () => {
  const authStore = useAuthStore();
  const user = authStore.user();

  if (user?.status == 'approved') {
    return null;
  }

  return (
    <>
      <SoftBox
        sx={{
          backgroundColor: 'rgba(255,87,51,0.1)',
          borderRadius: '6px',
          padding: '15px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <SoftTypography
          sx={{
            color: '#ff2400',
            fontSize: '14px',
            display: 'flex',
            padding: '10px',
          }}
        >
          <Info
            sx={{
              color: '#ff2400',
              width: '20px',
              height: '20px',
              marginRight: '8px',
              marginTop: '2px',
            }}
          />{' '}
          Votre KYC n'est pas encore approuvé, veuillez fournir des documents
          valides pour vérifier votre identité.
        </SoftTypography>
        <SoftTypography
          sx={{
            fontSize: '14px',
            paddingLeft: '10px',
            display: 'flex',
          }}
        >
          <Link to='/profil'>
            Aller au profil <ArrowForward />
          </Link>
        </SoftTypography>
      </SoftBox>
    </>
  );
};

export default KcyVerify;
