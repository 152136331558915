

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';

// Soft UI Dashboard React components
import SoftBox from '@components/SoftBox';
import SoftTypography from '@components/SoftTypography';
import './staticcard.css';

function MiniStatisticsCard({
  bgColor,
  title,
  count,
  percentage,
  icon,
  direction,
  image,
}) {
  return (
    <Card>
      <SoftBox bgColor={bgColor} variant='gradient'>
        <SoftBox p={2}>
          <Grid container alignItems='center'>
            {/* {direction === 'left' ? (
              <Grid item>
                <SoftBox
                  variant='gradient'
                  bgColor={bgColor === 'white' ? icon.color : 'white'}
                  color={bgColor === 'white' ? 'white' : 'dark'}
                  width='3rem'
                  minHeight='3rem'
                  borderRadius='md'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  shadow='md'
                >
                  <Icon fontSize='small' color='inherit'>
                    {icon.component}
                  </Icon>
                </SoftBox>
              </Grid>
            ) : null} */}
            <Grid item xs={8}>
              <SoftBox ml={direction === 'left' ? 2 : 0} lineHeight={1}>
                <SoftTypography
                  variant='h5'
                  fontSize={18}
                  // color={bgColor === 'white' ? 'text' : 'white'}
                  color={`#ff2400`}
                  opacity={bgColor === 'white' ? 1 : 0.7}
                  textTransform='capitalize'
                  fontWeight={title.fontWeight}
                >
                  {title.text}
                </SoftTypography>

                <SoftTypography
                  variant='h2'
                  fontWeight='bold'
                  color={`#3133DD`}

                  // color={bgColor === 'white' ? '#3133DD' : 'white'}
                >
                  {count}{' '}
                  <SoftTypography
                    variant='button'
                    color={percentage.color}
                    fontWeight='bold'
                  >
                    {percentage.text}
                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
            </Grid>
            {direction === 'right' ? (
              <Grid item xs={4}>
                <SoftBox
                  variant='gradient'
                  bgColor={bgColor === 'white' ? icon.color : 'white'}
                  color={bgColor === 'white' ? 'white' : 'dark'}
                  width='5rem'
                  height='5rem'
                  marginLeft='auto'
                  borderRadius='md'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  shadow='md'
                >
                  <Icon
                    fontSize='large'
                    color='inherit'
                    sx={{ textAlign: 'center' }}
                  >
                    {icon.component}
                    <img
                      src={image}
                      alt='logo'
                      width={35}
                      height={35}
                      className='icon-img-coin'
                    />
                  </Icon>
                </SoftBox>
              </Grid>
            ) : null}
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of MiniStatisticsCard
MiniStatisticsCard.defaultProps = {
  bgColor: 'white',
  title: {
    fontWeight: 'medium',
    text: '',
  },
  percentage: {
    color: 'success',
    text: '',
  },
  direction: 'right',
};

// Typechecking props for the MiniStatisticsCard
MiniStatisticsCard.propTypes = {
  bgColor: PropTypes.oneOf([
    'white',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(['light', 'regular', 'medium', 'bold']),
    text: PropTypes.string,
  }),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
      'white',
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
    ]),
    component: PropTypes.node.isRequired,
  }).isRequired,
  direction: PropTypes.oneOf(['right', 'left']),
};

export default MiniStatisticsCard;
