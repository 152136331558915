import {
  Configuration,
  EmailsApi,
  TemplatesApi,
} from "@elasticemail/elasticemail-client-ts-axios";

const config = new Configuration({
  apiKey: process.env.REACT_APP_EMAIL_API_KEY,
});
const emailsApi = new EmailsApi(config);
const templatesApi = new TemplatesApi(config);

export const findTemplate = async (name) => {
  return await templatesApi.templatesByNameGet(name);
};

export const sendMail = async (data, body = "") => {
  const payload = {
    Recipients: {
      To: [data.email], // maximum 50 recipients
    },
    Content: {
      Body: [
        {
          ContentType: "HTML",
          Charset: "utf-8",
          Content: body,
        },
      ],
      From: "info.recjhasce@gmail.com",
      Subject: data?.subject || "Subject",
    },
  };

  emailsApi
    .emailsTransactionalPost(payload)
    .then((response) => {
      console.log("API called successfully.");
      console.log(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const sendOtpEmail = async (data) => {
  try {
    const template = await findTemplate("otp-email");
    let templateBody = template.data.Body[0].Content;
    templateBody = templateBody.replace("{otp}", data.otp);

    await sendMail(
      { ...data, subject: "Your Verification OTP Code" },
      templateBody
    );
  } catch (err) {}

  return;
};

export const resetPasswordEmail = async (data) => {
  try {
    const template = await findTemplate("reset-password");
    let templateBody = template.data.Body[0].Content;
    templateBody = templateBody.replace("{link}", data.link);

    await sendMail(
      { ...data, subject: "Your Reset Password Link" },
      templateBody
    );
  } catch (err) {}

  return;
};
