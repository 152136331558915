import "firebase/compat/auth";
// import 'firebase/firestore' // <- needed if using firestore
// import 'firebase/functions' // <- needed if using httpsCallable
import { compose, createStore } from "redux";
import rootReducer from "./rootReducer";

export default function configureStore() {
  const createStoreWithMiddleware = compose(
    typeof window === "object" &&
      typeof window.devToolsExtension !== "undefined"
      ? () => window.__REDUX_DEVTOOLS_EXTENSION__ // eslint-disable-line no-underscore-dangle
      : (f) => f
  )(createStore);

  const store = createStoreWithMiddleware(rootReducer);

  // if (module.hot) {
  //   // Enable Webpack hot module replacement for reducers
  //   module.hot.accept("./rootReducer", () => {
  //     const nextRootReducer = require("./rootReducer"); // eslint-disable-line global-require, @typescript-eslint/no-var-requires
  //     store.replaceReducer(nextRootReducer);
  //   });
  // }

  return store;
}
