

import { useState } from "react";

// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SoftBox from "@components/SoftBox";
import SoftTypography from "@components/SoftTypography";

// Authentication layout components
import BasicLayout from "@layouts/authentication/components/BasicLayout";

// Images
import curved6 from "@assets/images/signUp.png";
import { PrimaryButton } from "@components/Button";
import { FormInput } from "@core/forms";
import lodash from "@core/lodash";
import { EmailService, ValidatorService } from "@core/services";
import { SMSService } from "@core/services/sms_service";
import { Hash } from "@core/utils/hash";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAuthStore } from "@store/AuthStore";
import { useForm } from "react-hook-form";

function SignUp() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [agreement, setAgremment] = useState(true);
  const [loading, setLoading] = useState(false);

  const { referralId } = useParams();
  const authStore = useAuthStore();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm();

  const handleSetAgremment = () => setAgremment(!agreement);

  const ShowPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    setLoading(true);

    const verifyCode = Math.floor(
      1111 + Math.random() * (9999 - 1111 + 1)
    ).toString();

    const mobileOtp = Math.floor(
      1111 + Math.random() * (9999 - 1111 + 1)
    ).toString();

    const actionCode = lodash.uniqueId(new Date().getTime().toString());

    try {
      await authStore.register(data.email, data.password, {
        ...data,
        verifyCode: verifyCode,
        mobileOtp: mobileOtp,
        code: actionCode,
        verified: false,
        password: Hash.encrypt(data.password),
        deletedAt: 0,
        referralId: referralId || "",
        role: "user",
      });

      await authStore.logout();

      // const user = await authStore.findByEmail(data.email);

      EmailService.sendOtpEmail({
        email: data.email,
        otp: verifyCode,
        name: data.name,
      });

      SMSService.sendOtpSms({
        phone: data.phone,
        otp: mobileOtp,
        name: data.name,
      });

      navigate(`/authentication/otp?email=${data.email}&code=${verifyCode}`);
    } catch (error) {
      const message = error.message
        .replace("Firebase:", "")
        .replace("(auth/email-already-in-use).", "");

      setError("email", {
        type: "manual",
        message: message,
      });
      setLoading(false);
      return;
    }

    localStorage.setItem(
      "fbRegisterHook",
      JSON.stringify({
        email: data.email,
        password: data.password,
      })
    );
  };

  return (
    <BasicLayout
      // title='Welcome!'
      // description='Please register to create an account.'
      image={curved6}
    >
      <Card>
        <SoftBox p={3} mb={0} textAlign="center">
          <SoftTypography
            variant="h2"
            fontWeight="medium"
            textGradient
            color="info"
          >
            S'inscrire et devenir membre!
          </SoftTypography>
        </SoftBox>
        {/* <SoftBox mb={2}>
          <Socials />
        </SoftBox>
        <Separator /> */}
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox
            component="form"
            role="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <SoftBox mb={2} sx={{ display: "flex", gap: "10px" }}>
              <FormInput
                type="text"
                control={register("name", {
                  required: "Nom et prénoms est requis",
                })}
                tepy="text"
                placeholder="Nom et prénoms"
                error={errors.name}
                sx={{ margin: "3px" }}
                withoutGroup
              />
              <FormInput
                type="email"
                control={register("email", {
                  required: "Email est requis",
                  validate: ValidatorService.email,
                })}
                className="form-control"
                placeholder="Email"
                error={errors.email}
                withoutGroup
              />
            </SoftBox>
            <SoftBox mb={2} sx={{ display: "flex", gap: "10px" }}>
              <FormInput
                type="number"
                control={register("phone", {
                  required: "Telephone est requis",
                })}
                tepy="text"
                placeholder="Telephone"
                error={errors.phone}
                withoutGroup
              />
              <FormInput
                type="text"
                control={register("region", {
                  required: "Région d'origine est requis",
                })}
                tepy="text"
                placeholder="Région d'origine"
                error={errors.region}
                withoutGroup
              />
            </SoftBox>
            <SoftBox mb={2} sx={{ display: "flex", gap: "10px" }}>
              <FormInput
                type="text"
                control={register("origin", {
                  required: "Département d'origine est requis",
                })}
                tepy="text"
                placeholder="Département d'origine"
                error={errors.origin}
                withoutGroup
              />

              <FormInput
                type="text"
                control={register("subdivision", {
                  required: "Arrondissement d'origine est requis",
                })}
                tepy="text"
                placeholder="Arrondissement d'origine"
                error={errors.subdivision}
                withoutGroup
              />
            </SoftBox>
            <SoftBox
              mb={2}
              sx={{ display: "flex", gap: "10px", position: "relative" }}
            >
              <FormInput
                type="text"
                control={register("village", {
                  required: "Village est requis",
                })}
                tepy="text"
                placeholder="Village"
                error={errors.village}
                withoutGroup
              />
              <FormInput
                type={!showPassword ? "password" : "text"}
                control={register("password", {
                  required: "Password est requis",
                  ...ValidatorService.passwordRules(),
                })}
                tepy="text"
                placeholder="Password"
                error={errors.password}
                withoutGroup
              />
              <div className="form-icon-pass-signup">
                {showPassword && (
                  <Visibility
                    className="form-icon cursor-pointer"
                    onClick={ShowPasswordHandler}
                  />
                )}
                {!showPassword && (
                  <VisibilityOff
                    className="form-icon cursor-pointer"
                    onClick={ShowPasswordHandler}
                  />
                )}
              </div>{" "}
            </SoftBox>
            <SoftBox display="flex" alignItems="center">
              <Checkbox checked={agreement} onChange={handleSetAgremment} />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                onClick={handleSetAgremment}
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                &nbsp;&nbsp;Je Suis D'accord Le&nbsp;
              </SoftTypography>
              <SoftTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                textGradient
                color="info"
              >
                <Link
                  to="https://recjhasce.com/wp-content/uploads/2024/05/Termes-Et-Conditions.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Termes Et Conditions
                </Link>
              </SoftTypography>
            </SoftBox>
            <SoftBox mt={4} mb={1}>
              <PrimaryButton
                variant="gradient"
                fullWidth
                type="submit"
                color="info"
                loading={loading}
              >
                S'inscrire
              </PrimaryButton>
            </SoftBox>

            <SoftBox mt={3} textAlign="center">
              <SoftTypography
                variant="button"
                color="text"
                fontWeight="regular"
              >
                Vous avez déjà un compte?&nbsp;
                <SoftTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="bold"
                  textGradient
                  type="submit"
                >
                  Se Connecter
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>
    </BasicLayout>
  );
}

export default SignUp;
