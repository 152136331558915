import { apiPost } from "@core/utils/api/api";

export const sendSms = async (data, message = "") => {
  const response = await apiPost(
    `https://us-central1-recjhasce-cameroon.cloudfunctions.net/sendCloudSms`,
    {
      msg: message,
      to: data?.phone,
    },
    "POST"
  );
  console.log("response", response);
  return response;
};

export const sendOtpSms = async (data) => {
  return await sendSms(data, `Your verification OTP code is: ${data.otp}`);
};
