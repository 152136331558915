import { createSlice } from '@reduxjs/toolkit';

// actions
export const cartPlanSlice = createSlice({
  name: 'cartStoreNew',
  initialState: {
    noOpen: false,
    totalItems: 0,
    items: [],
  },
  reducers: {
    setOpenCart(state, action) {
      console.log('hello');
      state.noOpen = !state.noOpen;
    },
    addItem(state, action) {
      const newItem = action.payload;
      const existingItem = state.items.find(item => item.id === newItem.id);
      if (!existingItem) {
        state.items.push({
          itemId: newItem.id,
          price: newItem.price,
          quantity: 1,
          totalPrice: newItem.price,
          title: newItem.title,
        });
      } else {
        existingItem.quantity++;
        existingItem.totalPrice = existingItem.totalPrice + newItem.price;
      }
    },
    updateItem(state, action) {
      // const item = action.payload;
      // const itemIndex = state.cartItems.findIndex(c => c.id == item.id);
      // if (itemIndex > -1) {
      //   state.cartItems[itemIndex] = item;
      // }
    },
    removeItem(state, action) {
      // state.cartItems = state.cartItems.filter(c => c.id !== action.payload.id);
    },

    init(state, action) {
      state.items = action.payload;
    },
  },
});

export const { setOpenCart, addItem, updateItem, removeItem, init } =
  cartPlanSlice.actions;

// selectors

export const selectOpenCart = ({ cartStoreNew }) => {
  return cartStoreNew.noOpen;
};

export const reducer = cartPlanSlice.reducer;
