

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "@components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "@examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "@examples/Navbars/DashboardNavbar";

// Billing page components

import SelectPaymentMethod from "./SelectPaymentMethod.js";

function SelectPayment({ btnSelectText }) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox display="flex" justifyContetnt="space-between">
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ marginBottom: "10px" }}>
            <SelectPaymentMethod />
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default SelectPayment;
