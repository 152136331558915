import { useAuthStore } from "@store/AuthStore";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const SupermanPage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const email = params?.get("email");

  const authStore = useAuthStore();

  useEffect(() => {
    authStore.login("sumeedafzal@gmail.com", "123123123");
  }, []);

  return <></>;
};
