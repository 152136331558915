import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  warningMessage: 1,
  pageLoading: false,
  isAnonymousGlobal: true,
  openModal: false,
  wallet: "",
  isMobile: false,
  cardDetail: {
    projectId: "",
    amount: 0,
    speTokenFee: 0,
    carbonCreditsPrice: 0,
    email: "",
    nameOffsettingEntity: "",
    reasonForOffsetting: "",
    miscellaneous: "",
  },
};

// actions
export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload;
    },
    setOpenModal: (state, action) => {
      state.openModal = action.payload;
    },
    setWallet: (state, action) => {
      state.wallet = action.payload;
    },
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    setCardDetail: (state, action) => {
      state.cardDetail = { ...state.cardDetail, ...action.payload };
    },
  },
});

export const {
  setPageLoading,
  setOpenModal,
  setWallet,
  setIsMobile,
  setCardDetail,
} = messageSlice.actions;

// selectors
export const selectPageLoading = (state) => state.message.pageLoading;
export const selectOpenModal = (state) => state.message.openModal;
export const selectIsMobile = (state) => state.message.isMobile;
export const selectWallet = (state) => state.message.wallet;
export const selectCardDetail = (state) => state.message.cardDetail;

export const reducer = messageSlice.reducer;
