import curved9 from "@assets/images/signIn.png";
import { PrimaryButton } from "@components/Button";
import SoftBox from "@components/SoftBox";
import SoftTypography from "@components/SoftTypography";
import { FormInput } from "@core/forms";
import { EmailService } from "@core/services";
import { ArrowBack } from "@mui/icons-material";
import { useAuthStore } from "@store/AuthStore";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { useNavigate, useSearchParams } from "react-router-dom";
import CoverLayout from "../components/CoverLayout";

export const VerifyOtpPage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const email = String(params?.get("email")).replace(" ", "+");

  const authStore = useAuthStore();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const verifyPasswordHandler = (e) => {
    e.preventDefault();
    EmailService.sendOtpEmail({
      email: "webninjja@gmail.com",
      otp: "1234",
    });
    // navigate("/authentication/verify-password");
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const user = await authStore.findByEmail(email);

    if (!user?.id) {
      setError("emailOtp", {
        type: "manual",
        message: "Invalid email",
      });
      setLoading(false);
      return;
    }

    if (user?.verifyCode !== data.emailOtp) {
      setError("emailOtp", {
        type: "manual",
        message: "Invalid email otp",
      });
      setLoading(false);
      return;
    }

    // if (user?.mobileOtp !== data.smsOtp) {
    //   setError("smsOtp", {
    //     type: "manual",
    //     message: "Invalid sms otp",
    //   });
    //   setLoading(false);
    //   return;
    // }

    NotificationManager.success(
      "Email and SMS OTP verified successfully",
      "",
      2000
    );

    await authStore.updateItem({ ...user, verified: true });

    navigate("/authentication/sign-in");
    setLoading(false);
  };

  return (
    <>
      <CoverLayout
        ArBack={<ArrowBack />}
        back="Go Back"
        title="Verify Otp"
        description="Send OTP on Mobile & Email"
        image={curved9}
      >
        <SoftBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
          <SoftBox mb={2}>
            <FormInput
              type="number"
              control={register("emailOtp", {
                required: "Email Otp is required",
              })}
              className="form-control"
              placeholder="Email Otp"
              label="Email Otp"
              error={errors.emailOtp}
            />
          </SoftBox>

          <SoftBox
            sx={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <SoftTypography
              fontWeight="bold"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#444",
                width: "50px",
                color: "#fff",
                height: "50px",
                zIndex: "2",
                borderRadius: "50px",
                position: "relative",
              }}
            >
              AND
            </SoftTypography>
            <SoftTypography
              fontWeight="bold"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                width: "200px",
                height: "2px",
                top: "25px",

                zIndex: "1",
                backgroundColor: "#444",
              }}
            />
          </SoftBox>

          <SoftBox mb={2}>
            <FormInput
              type="number"
              control={register("smsOtp", {
                // required: "SMS Otp is required",
              })}
              className="form-control"
              placeholder="SMS Otp"
              label="SMS Otp"
              error={errors.smsOtp}
            />
          </SoftBox>

          <SoftBox mt={4} mb={1}>
            <PrimaryButton
              loading={loading}
              variant="gradient"
              color="info"
              fullWidth
              type="submit"
            >
              Verify
            </PrimaryButton>
          </SoftBox>
        </SoftBox>
      </CoverLayout>
    </>
  );
};
