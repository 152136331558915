export const firebase = {
  apiKey: "AIzaSyBv1HjsxG2KI-v4Mr8-ROQ2ApnR6_dGVuk",
  authDomain: "recjhasce-cameroon.firebaseapp.com",
  projectId: "recjhasce-cameroon",
  storageBucket: "recjhasce-cameroon.appspot.com",
  messagingSenderId: "670486046051",
  appId: "1:670486046051:web:9c20c9919c6395eed5df56",
  measurementId: "G-1Y7BD70886",
  databaseURL: "https://recjhasce-cameroon-default-rtdb.firebaseio.com",
};

export const reduxFirebase = {
  userProfile: "users",
  useFirestoreForProfile: true,
};

export default { firebase, reduxFirebase };
