import SoftButton from "@components/SoftButton";
import { CircularProgress } from "@mui/material";

export const PrimaryButton = (props) => {
  const { loading } = props;
  return (
    <SoftButton
      type="submit"
      cornered
      green
      className={` w-100 ${props?.className}`}
      disabled={loading}
      {...props}
    >
      <div className="d-flex gap-10">
        {loading ? (
          <CircularProgress color="secondary" size={18} />
        ) : (
          <>{props.children}</>
        )}
      </div>
    </SoftButton>
  );
};
