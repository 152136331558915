import __ from "lodash";

/**
 * You can extend Lodash with mixins
 * And use it as below
 * import lodash from '@lodash'
 */
const lodash = __.runInContext();

lodash.mixin({
  // Immutable Set for setting state
  setIn: (state, name, value) => {
    return lodash.setWith(lodash.clone(state), name, value, lodash.clone);
  },
});

export default lodash;
