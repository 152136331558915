import C1 from '@assets/images/member-cards/1-student.jpeg';
import { PLAN_CARDS } from '@core/constants/plans';
import './PurchasedCard.css';
import { Grid } from '@mui/material';

const PurchasedCard = ({
  title,
  code,
  cardName,
  bgCard,
  planId,
  price,
  buyDate,
}) => {
  const plan = PLAN_CARDS.find(c => c.id == planId);
  return (
    <>
      <div className='mship-card-body'>
        <div className='mship-detail-secion '>
          <h3 className='mship-card-name'> {cardName} </h3>
          <h3 className='mship-title'> {title} </h3>
          <p className='buy-date'>
            Joined: {buyDate}
            {/* <span className='price-box'> {price} </span> */}
          </p>
          <p className='mship-code'>{code} </p>
        </div>

        <img src={plan?.image || C1} alt='card' className='memb-card' />
      </div>
    </>
  );
};

export default PurchasedCard;
