import { useUserStore } from "@store/UserStore";
import { useSelector } from "react-redux";
import { useFirebase } from "react-redux-firebase";

export const useAuthStore = () => {
  const userStore = useUserStore();
  const firebase = useFirebase();

  const auth = useSelector((state) => state?.firebase?.auth);

  const authId = auth?.uid;

  const user = () => {
    if (!auth?.uid) {
      return null;
    }
    const user = userStore.selectItem(auth.uid);

    if (!user?.email && false) {
      return userStore.getItem(auth.uid).then((res) => {
        return { ...res.data(), id: res.id };
      });
    }

    return user;
  };

  const login = (email, password) => {
    return firebase.login({ email, password });
  };

  const findByEmail = async (email) => {
    return userStore.getItemsByQuery([["email", "==", email]]).then((res) => {
      return res.docs.length > 0
        ? { ...res.docs[0].data(), id: res.docs[0].id }
        : null;
    });
  };

  const findByResetCode = async (code) => {
    return userStore
      .getItemsByQuery([["resetCode", "==", code]])
      .then((res) => {
        return res.docs.length > 0
          ? { ...res.docs[0].data(), id: res.docs[0].id }
          : null;
      });
  };

  const register = (email, password, data) => {
    return firebase.createUser(
      { email, password, signIn: false },
      {
        ...data,
        email: email,
        username: email,
        createdAt: new Date().getTime(),
        updatedAt: new Date().getTime(),
      }
    );
  };

  const logout = () => {
    return firebase.logout();
  };

  const resetPassword = (email) => {
    return firebase.resetPassword(email);
  };

  const verifyPasswordResetCode = (email) => {
    return firebase.verifyPasswordResetCode(email);
  };

  return {
    ...userStore,
    user,
    register,
    login,
    logout,
    firebase,
    authId,
    findByEmail,
    findByResetCode,
  };
};
