

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "@components/SoftBox";
import SoftTypography from "@components/SoftTypography";

function AffiliateStaticCard({
  bgColor,
  title,
  subText,
  percentage,
  icon,
  direction,
}) {
  return (
    <Card sx={{ border: "1px solid #d1d1d1" }}>
      <SoftBox bgColor={bgColor} variant="gradient">
        <SoftBox p={2}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <SoftBox
                ml={direction === "left" ? 2 : 0}
                lineHeight={1}
                justifyContent="center"
                display="flex"
                flexDirection="column"
                alignItems="center"
                minHeight="150px"
                alignContent="center"
              >
                <SoftBox
                  variant="gradient"
                  bgColor={bgColor === "white" ? icon.color : "white"}
                  color={bgColor === "white" ? "white" : "dark"}
                  width="3rem"
                  height="3rem"
                  borderRadius="xxl"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md"
                >
                  <Icon fontSize="small" color="inherit">
                    {icon.component}
                  </Icon>
                </SoftBox>
                <SoftTypography
                  variant="h3"
                  textAlign="center"
                  textTransform="capitalize"
                  fontWeight={title.fontWeight}
                  pt={2}
                  sx={{
                    fontSize: "1.875rem",
                    width: "70%",
                    color: "#0842ac",
                    fontWeight: "600",
                  }}
                >
                  {title.text}
                </SoftTypography>
                <SoftTypography
                  variant="button"
                  fontWeight="bold"
                  justifyContent="center"
                  color={bgColor === "white" ? "dark" : "white"}
                >
                  {subText}
                </SoftTypography>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of AffiliateStaticCard
AffiliateStaticCard.defaultProps = {
  bgColor: "white",
  title: {
    fontWeight: "medium",
    text: "",
  },
  percentage: {
    color: "success",
    text: "",
  },
  direction: "right",
};

// Typechecking props for the AffiliateStaticCard
AffiliateStaticCard.propTypes = {
  bgColor: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    text: PropTypes.string,
  }),
  subText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
    ]),
    component: PropTypes.node.isRequired,
  }).isRequired,
  direction: PropTypes.oneOf(["right", "left"]),
};

export default AffiliateStaticCard;
