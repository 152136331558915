import lodash from "@core/lodash";
import { toMoment } from "@core/utils/time";
import _ from "lodash";

export class BaseStore {
  collection = null;
  firestore = null;
  items = [];

  queried = [];

  initialized = false;

  constructor(collection, firestore, selector = null) {
    this.collection = collection;
    this.firestore = firestore;
    this.selector = selector;
    this.items = [];

    this.init();
  }

  // items = ({ firestore }) => {
  //   const items = firestore.data[this.collection];
  //   return lodash.sortBy(
  //     lodash.values(items).filter((i) => i),
  //     ["createdAt"]
  //   );
  // };

  init() {
    try {
      this.items = lodash.sortBy(
        lodash
          .values(
            this.selector((state) => {
              // let queriedItems = this.queried.map((q) => {
              //   return state.firestore.data[q];
              // });
              // return queriedItems.concat(state.firestore.data[this.collection]);
              return state.firestore.data[this.collection];
            })
          )
          .filter((i) => i),
        ["createdAt"]
      );
    } catch (error) {}
  }

  firstItem = () => {
    return _.first(this.items);
  };

  selectItem = (id) => {
    return this.items.filter((i) => i).find((i) => i.id === id);
  };

  selectWithField = (name, value) => {
    return this.items.filter((i) => i).filter((i) => i[name] === value);
  };

  selectFirstWithField = (name, value) => {
    return this.items?.filter((i) => i).find((i) => i[name] == value);
  };

  createItem = (data) => {
    return this.firestore.add(this.collection, {
      ...data,
      createdAt: toMoment().unix(),
      updatedAt: toMoment().unix(),
    });
  };

  createOrUpdateItem = (data) => {
    if (data.id) {
      return this.updateItem(data);
    } else {
      return this.createItem(data);
    }
  };

  deleteItem = (id) => {
    return this.firestore.delete(`${this.collection}/${id}`);
  };

  updateItem = (data) => {
    return this.firestore.update(`${this.collection}/${data.id}`, {
      ...data,
      updatedAt: toMoment().unix(),
    });
  };

  getItem = (id) => {
    return this.firestore.get(`${this.collection}/${id}`);
  };

  fetchItems = () => {
    return this.firestore.get(this.collection);
  };

  flatResponse = (res) => {
    return res.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });
  };

  paginateItems = (limit = 20, orderBy = "createdAt") => {
    const lastId = this.items[this.items.length - 1]?.id;
    this.firestore
      .get({
        collection: this.collection,
        orderBy: orderBy,
        limit: limit,
        startAfter: lastId,
      })
      .then((items) => {
        this.items = this.items.concat(
          lodash.sortBy(
            lodash.values(items).filter((i) => i),
            ["createdAt"]
          )
        );
      });
  };

  getItemsByQuery = (query, orderBy, limit) => {
    return this.firestore.get({
      collection: this.collection,
      where: query,
      orderBy: orderBy,
      limit: limit,
    });
  };

  getItems = () => {
    return this.items;
  };

  getFirestore = () => {
    return this.firestore;
  };
}
