import curved9 from '@assets/images/signIn.png';
import { PrimaryButton } from '@components/Button';
import SoftBox from '@components/SoftBox';
import SoftTypography from '@components/SoftTypography';
import { FormInput } from '@core/forms';
import { EmailService, ValidatorService } from '@core/services';
import { Hash } from '@core/utils/hash';
import { ArrowBack } from '@mui/icons-material';
import { useAuthStore } from '@store/AuthStore';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';
import CoverLayout from '../components/CoverLayout';

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const authStore = useAuthStore();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const onSubmit = async data => {
    setLoading(true);
    const user = await authStore.findByEmail(data?.email);

    const resetCode = Hash.uniqueId();

    if (!user?.id) {
      setError('email', {
        type: 'manual',
        message: 'Invalid email',
      });
      setLoading(false);
      return;
    }

    NotificationManager.success('Reset Link  sent successfully', '', 2000);

    authStore.updateItem({ id: user?.id, resetCode: resetCode });

    await EmailService.resetPasswordEmail({
      email: data.email,
      link:
        window.location.origin + '/authentication/reset-password/' + resetCode,
      name: data.name,
    });

    // navigate("/authentication/sign-in");
    setLoading(false);
  };

  return (
    <>
      <CoverLayout
        ArBack={<ArrowBack />}
        back='Go Back'
        title='Réinitialisez votre mot de passe'
        description='Envoyer OTP par e-mail'
        image={curved9}
      >
        <SoftBox component='form' role='form' onSubmit={handleSubmit(onSubmit)}>
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography
                component='label'
                variant='caption'
                fontWeight='bold'
              >
                Email
              </SoftTypography>
            </SoftBox>
            <FormInput
              type='email'
              control={register('email', {
                required: 'Email is required',
                validate: ValidatorService.email,
              })}
              className='form-control'
              placeholder='Email'
              error={errors.email}
            />
          </SoftBox>

          <SoftBox mt={4} mb={1}>
            <PrimaryButton
              loading={loading}
              variant='gradient'
              color='info'
              fullWidth
              type='submit'
            >
              Envoyer OTP
            </PrimaryButton>
          </SoftBox>
        </SoftBox>
      </CoverLayout>
    </>
  );
};

export default ForgotPasswordPage;
