import { Hash } from "@core/utils/hash";
import { storage } from "@store/index";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useFirebase } from "react-redux-firebase";

export const useFileStore = () => {
  const firebase = useFirebase();

  const collection = "uploadedFiles";

  const upload = async (file, folder = "profile") => {
    if (file?.name) {
      const extension = file.name.split(".").pop();
      const newFileName = `${folder}/${Hash.uniqueId()}.${extension}`;
      const storageRef = ref(storage, `${folder}/${newFileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file, {
        contentType: file.type,
        name: file.name,
        size: file.size,
      });

      await uploadTask;

      return await getDownloadURL(uploadTask.snapshot.ref);
    }

    return null;
  };

  return {
    upload,
  };
};
