import './card.css';

import { useState } from 'react';
import { Link } from 'react-router-dom';

const PlanCard = props => {
  const { title, reward, cardImg, noGutter, price, buttonTxt } = props;
  const [select, setSelect] = useState(false);
  const [number, setNumber] = useState(1);
  const [error, setError] = useState('');

  return (
    <>
      <div className='card-layout'>
        <div className='card-price'>{price}</div>
        <h2 className='card-heading'>{title}</h2>
        <p className='card-sub-heading'>{reward}</p>
        <div className='card-price-section'>
          <Link
            // to="/plans/checkout"
            onClick={() => {
              if (props?.onClick) {
                props.onClick();
              }
              return false;
            }}
          >
            <span className='card-btn'>{buttonTxt}</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default PlanCard;
