import {
  FormGroup,
  FormHelperText,
  FormControl as MFormControl,
} from "@mui/material";
import _ from "lodash";
import { Fragment, useCallback, useMemo } from "react";

import SoftBox from "@components/SoftBox";
import SoftInput from "@components/SoftInput";
import SoftTypography from "@components/SoftTypography";
import clsx from "clsx";

export function FormInput(props) {
  const {
    type = "text",
    readonly,
    withoutGroup = false,
    bsSize = "medium",
    showLabel = true,
    label,
    labelIcon,
    labelClassName,
    control,
    size,
    value,
    defaultValue,
    error,
    inputParentStyles,
    forceValue,
    ...rest
  } = props;

  const controlId = useMemo(() => `form-input-${Math.random()}`, []);

  const handleChange = useCallback(
    (event) => {
      let value = event.target.value;

      if (type === "number") {
        if (value) value = +value;
        if (_.isNaN(value)) value = null;
      }
    },
    [type]
  );

  const handleFocus = useCallback(() => {}, []);

  const handleBlur = useCallback(() => {}, []);

  let inputContent = (
    <div className="form-input-field" style={{ ...inputParentStyles }}>
      {props.type === "textarea" ? (
        <SoftInput
          type={props.type}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          required={props.required}
          disabled={props?.disabled}
          readonly={readonly}
          placeholder={props?.placeholder}
          sx={{ margin: "3px" }}
          style={{ ...props.style }}
          value={props?.value || props?.defaultValue}
          {...control}
        />
      ) : (
        <SoftInput
          type={props.type}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          required={props.required}
          disabled={props?.disabled}
          readonly={readonly}
          placeholder={props?.placeholder}
          error={error?.message}
          sx={{ margin: "3px" }}
          style={{ ...props.style }}
          value={props?.value || props?.defaultValue}
          {...control}
        />
      )}
    </div>
  );

  const content = (
    <Fragment>
      <MFormControl
        className={clsx(props?.groupClassName)}
        error={!!error?.message}
        fullWidth
      >
        {/* {showLabel && !!props?.label && (
          <FormLabel className={props?.labelClassName}>
            {props?.label}
          </FormLabel>
        )} */}
        {showLabel && !!props?.label && (
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              {props?.label}
            </SoftTypography>
          </SoftBox>
        )}

        {inputContent}
        {!!error?.message && (
          <FormHelperText
            className="ml-0 mr-0"
            style={{ color: "#f44336", marginLeft: 0 }}
          >
            {error?.message}
          </FormHelperText>
        )}
      </MFormControl>
    </Fragment>
  );

  if (withoutGroup) return content;

  return (
    <FormGroup
      {...props.groupProps}
      style={{ ...props.groupStyles, marginBottom: 12 }}
    >
      {content}
    </FormGroup>
  );
}
