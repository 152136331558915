// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "@components/SoftBox";

// Soft UI Dashboard React base styles
import borders from "@assets/theme/base/borders";

// Images
import amexCardLogo from "@assets/images/logos/amex-card-icon.svg";
import masterCardLogo from "@assets/images/logos/master-card-icon.svg";
import visaCardLogo from "@assets/images/logos/visa-card-icon.svg";

import SoftTypography from "@components/SoftTypography";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useAuthStore } from "@store/AuthStore";
import { CardElement } from "@stripe/react-stripe-js";
import { useState } from "react";

const SavedCards = (props) => {
  const { borderWidth, borderColor } = borders;
  const [chooseCard, setChooseCard] = useState("new");

  const authStore = useAuthStore();
  const user = authStore.user();

  const paymentMethods = user?.paymentMethods || [];

  const handleOptionChange = (event) => {
    setChooseCard(event.target.value);
    if (props?.onSelectCard) {
      props?.onSelectCard(event.target.value);
    }
  };

  const cardImages = {
    Visa: visaCardLogo,
    "American Express": amexCardLogo,
    MasterCard: masterCardLogo,
  };

  return (
    <RadioGroup
      row
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="row-radio-buttons-group"
    >
      <Grid container spacing={2}>
        {paymentMethods.map((card) => (
          <Grid item xs={12} md={6} key={card?.id}>
            <SoftBox
              border={`${borderWidth[1]} solid ${borderColor}`}
              borderRadius="lg"
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              px={3}
              py={2}
            >
              <FormControlLabel
                ml={2}
                value={card?.id}
                control={<Radio mt={3} sx={{ marginTop: "4px" }} />}
                // label='Credit / Debit Card'
                sx={{
                  lineHeight: "0px",
                  width: "24px",
                }}
                checked={chooseCard === card?.id}
                onChange={handleOptionChange}
              />
              <SoftBox
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: "12px",
                  width: "100%",
                }}
              >
                <SoftBox
                  component="img"
                  src={cardImages[card?.brand] || visaCardLogo}
                  height="100%"
                  alt="card"
                  width="20%"
                  mr={1}
                />
                <SoftBox
                  sx={{
                    diplay: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <SoftTypography
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    **** **** **** {card?.last4}
                  </SoftTypography>
                  <SoftTypography
                    sx={{
                      fontSize: "14px",
                      float: "right",
                    }}
                  >
                    {card?.expiryMonth}/{card?.expiryYear}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Grid>
        ))}

        <Grid item xs={12} md={6}>
          <SoftBox
            border={`${borderWidth[1]} solid ${borderColor}`}
            borderRadius="lg"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            px={3}
            py={2}
          >
            <FormControlLabel
              ml={2}
              value="new"
              control={<Radio mt={3} sx={{ marginTop: "4px" }} />}
              // label='Credit / Debit Card'
              sx={{
                lineHeight: "0px",
                width: "24px",
              }}
              checked={chooseCard === "new"}
              onChange={handleOptionChange}
            />
            <SoftBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "12px",
                width: "100%",
              }}
            >
              <SoftBox
                component="img"
                src={visaCardLogo}
                height="100%"
                alt="visa-card"
                width="20%"
                mr={1}
              />
              <SoftBox
                sx={{
                  diplay: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <SoftTypography
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  New Card
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
    </RadioGroup>
  );
};

function PaymentOptions(props) {
  const { cartItems = [] } = props;

  const [chooseCard, setChooseCard] = useState("new");

  const authStore = useAuthStore();
  const user = authStore.user();

  const paymentMethods = user?.paymentMethods || [];

  const onSelectCard = (value) => {
    setChooseCard(value);
    if (props?.onSelectCard) {
      props?.onSelectCard(value);
    }
  };

  return (
    <SoftBox mb={2} mt={2}>
      <Grid
        container
        spacing={3}
        p={1}
        pl={2}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {paymentMethods.length > 0 && (
          <Grid xs={12} lg={12} className="mb-30">
            <SoftBox pt={2} pl={2}>
              <SoftBox width="100%">
                <SavedCards onSelectCard={(value) => onSelectCard(value)} />
              </SoftBox>
            </SoftBox>
          </Grid>
        )}

        {chooseCard === "new" && (
          <Grid xs={12} lg={12}>
            <SoftBox pt={2} pl={2}>
              <SoftBox width="100%">
                <CardElement
                  className="form-control"
                  options={{
                    hidePostalCode: true,
                  }}
                />
              </SoftBox>
            </SoftBox>
          </Grid>
        )}
      </Grid>
    </SoftBox>
  );
}

export default PaymentOptions;
