import lodash from "@core/lodash";
import moment from "moment";

export { moment };

const DATE_FORMAT = "DD/MM/YYYY";

export function parseMoment(value, format, options) {
  const utc = options && options.utc === true ? true : false;
  const strict = options && options.strict === false ? false : true;

  return (utc ? moment.utc : moment)(value, format || undefined, strict);
}

export function toMoment(value, options) {
  const utc = options && options.utc === true ? true : false;
  const clone = options && options.clone === false ? false : true;

  if (moment.isMoment(value)) return clone ? value.clone() : value;
  if (value instanceof Date) {
    value = Math.round(value.valueOf() / 1000);
  }

  if (value && lodash.isNumber(value) && String(value).length === 10) {
    value = value * 1000;
  }

  if (lodash.isNull(value)) value = undefined;

  return (utc ? moment.utc : moment)(value);
}

export function formatTime(value, format = DATE_FORMAT, options = {}) {
  const utc = options.utc !== false && lodash.isNumber(options.utcOffset);

  const m = value && toMoment(value, { utc });
  if (!m || !m.isValid()) return "";

  if (lodash.isNumber(options.utcOffset)) {
    m.add(options.utcOffset, "minutes");
  }

  return m.format(format);
}
