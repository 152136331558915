import { useState } from 'react';
import '../../layouts/styles/sass/abstracts/_extend.scss';
import '../../layouts/styles/sass/base/_fonts.scss';
import '../../layouts/styles/sass/components/_custom-upload.scss';
import '../../layouts/styles/sass/layout/_kyc.scss';
// importing core styling file
import { PrimaryButton } from '@components/Button';
import { FormInput } from '@core/forms';
import { Hash } from '@core/utils/hash';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthStore } from '@store/AuthStore';
import '../../layouts/styles/sass/abstracts/_variables.scss';

export const UploadImage = props => {
  const { label = '', preview = '' } = props;
  const [file, setFile] = useState(null);
  const [rawFile, setRawFile] = useState(null);
  const inputId = Hash.uniqueId();

  const onFileChange = async e => {
    const file = e.target.files[0];
    setRawFile(file);
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setFile({
        id: Hash.uniqueId(),
        url: `data:${file.type};base64,${btoa(reader.result)}`,
        type: 'image',
      });

      if (props?.onChange) {
        props.onChange({
          file: file,
          url: `data:${file.type};base64,${btoa(reader.result)}`,
        });
      }
    };

    reader.readAsBinaryString(file);
  };

  return (
    <>
      <div
        className='custom-upload'
        style={file?.url || preview ? { padding: 2 } : {}}
      >
        <label htmlFor={inputId}>
          {file?.url || preview ? (
            <img
              src={file?.url || preview}
              className='avatar-image'
              style={{
                width: '100%',
                maxWidth: '110px',
                margin: 0,
                padding: 0,
              }}
            />
          ) : (
            <span>
              <FontAwesomeIcon icon={faCloudArrowUp} />
            </span>
          )}
        </label>
        <input
          id={inputId}
          className='fileUp'
          accept='image/*'
          type='file'
          name='nid-back'
          onChange={onFileChange}
        />
      </div>
      <p>{label}</p>
    </>
  );
};

function KycCard({
  activeID,
  stepForword,
  submit,
  stepBackword,
  hookForm,
  loading = false,
}) {
  const authStore = useAuthStore();
  const user = authStore.user();
  const [uploadError, setUploadError] = useState('');
  const [isSelectedError, setIsSelectedError] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedNid, setIsCheckedNid] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    getValues,
    watch,
  } = hookForm;

  const isNic = watch('isNic');
  const isVoteId = watch('isVoterId');

  const getUploadValues = () => {
    let value = null;
    if (getValues('isNic')) {
      value =
        !getValues('nicFrontImage') ||
        !getValues('nicBackImage') ||
        !getValues('nicSelfieImage') ||
        null;
    }

    if (!value && getValues('isVoterId')) {
      value = !getValues('voterIdImage');
    }

    return value;
  };

  // const uploadValues =
  //   !getValues("nicFrontImage") ||
  //   !getValues("nicBackImage") ||
  //   !getValues("nicSelfieImage") ||
  //   null;

  const nicValidation =
    isNic == true
      ? !watch('nicFrontImage') ||
        !watch('nicBackImage') ||
        !watch('nicSelfieImage')
      : false;

  const voterIdValidation = isVoteId == true ? !watch('voterIdImage') : false;

  const uploadValues = nicValidation || voterIdValidation;

  const selectedValue = isCheckedNid || isChecked;

  const checkFieldsHandler = () => {
    if (uploadValues) {
      setUploadError('Veuillez télécharger les images requises');
    } else {
      stepForword();
    }
  };
  const checkFieldSelectedHandler = () => {
    if (!selectedValue) {
      setIsSelectedError('Please Select ID Type.');
    } else {
      stepForword();
    }
  };

  const vIDcheckHandler = e => {
    console.log(e.target.checked);
    setIsChecked(e.target.checked);
  };
  const nIDcheckHandler = e => {
    console.log(e.target.checked);
    setIsCheckedNid(e.target.checked);
  };

  //getValues('nicFrontImage');
  return (
    <section className='kyc padding-top padding-bottom'>
      <div className='container'>
        <div className='kyc__wrapper'>
          <div className='kyc__form' id='kycForm'>
            {/* start step indicators */}
            <div className='form-header d-flex'>
              <span
                className={`stepIndicator ${activeID >= 1 ? 'active' : ''} ${
                  activeID >= 2 ? 'finish' : ''
                }`}
              >
                Taper
              </span>
              <span
                className={`stepIndicator ${activeID >= 2 ? 'active' : ''} ${
                  activeID >= 3 ? 'finish' : ''
                }`}
              >
                Téléchargements
              </span>

              <span
                className={`stepIndicator ${activeID >= 3 ? 'active' : ''} ${
                  activeID >= 4 ? 'finish' : ''
                }`}
              >
                Détails
              </span>
            </div>
            {/* end step indicators */}
            {/* step one */}
            <div
              className='step'
              style={{ display: activeID == 1 ? 'block' : 'none' }}
            >
              <h4 className='text-center'>
                Choisissez l'option de vérification
              </h4>
              <div className='form-group mb-5'>
                <h6>Choisissez une région</h6>
                <select
                  className='form-select'
                  aria-label='Select Region'
                  required
                  {...register('region', {
                    required: 'Region is required',
                  })}
                >
                  <option value='Cameroonian'>Cameroonian</option>
                  {/* <option>UK</option>
                  <option>Canada</option>
                  <option>France</option>
                  <option>Spain</option> */}
                </select>
              </div>
              <div className='form-group mb-1'>
                <h6>Sélectionnez le type d'identité</h6>
                <div className='select-group'>
                  <div className='form-check'>
                    <input
                      checked={isCheckedNid}
                      onClick={nIDcheckHandler}
                      className='form-check-input'
                      type='checkbox'
                      name='identy-check'
                      id='nid-check'
                      {...register('isNic', {
                        // required: 'Passport is required',
                      })}
                    />
                    <label className='form-check-label' htmlFor='nid-check'>
                      National ID
                    </label>
                  </div>
                  <div className='form-check'>
                    <input
                      checked={isChecked}
                      className='form-check-input'
                      type='checkbox'
                      name='identy-check'
                      id='passport-check'
                      onClick={vIDcheckHandler}
                      // defaultChecked={user?.kyc?.isVoterId}
                      {...register('isVoterId', {})}
                    />
                    <label
                      className='form-check-label'
                      htmlFor='passport-check'
                    >
                      Voter ID
                    </label>
                  </div>

                  <span className='uploadError'>
                    {!selectedValue && isSelectedError}
                  </span>
                </div>
              </div>
            </div>
            {/* step two */}
            <div
              className='step'
              style={{
                display: activeID == 2 ? 'block' : 'none',
                content: activeID == 2 ? 'block' : 'none',
              }}
            >
              <h4 className='text-center'>Télécharger des documents</h4>
              <ul className='upload__list  mb-1'>
                {isCheckedNid && (
                  <>
                    <li className='upload__item'>
                      <UploadImage
                        label='NID Photo de face*'
                        onChange={file => setValue('nicFrontImage', file?.file)}
                        preview={user?.kyc?.nicFrontImage}
                      />
                    </li>
                    <li className='upload__item'>
                      <UploadImage
                        label='NID Photo de dos*'
                        onChange={file => setValue('nicBackImage', file?.file)}
                        preview={user?.kyc?.nicBackImage}
                      />
                    </li>
                    <li className='upload__item '>
                      <UploadImage
                        label='Selfie avec NID*'
                        onChange={file =>
                          setValue('nicSelfieImage', file?.file)
                        }
                        preview={user?.kyc?.nicSelfieImage}
                      />
                    </li>
                  </>
                )}
                <li className='upload__item '>
                  {isChecked && (
                    <UploadImage
                      label='Voter ID'
                      onChange={file => setValue('voterIdImage', file?.file)}
                      preview={user?.kyc?.voterIdImage}
                    />
                  )}
                </li>
              </ul>
              <ul className='rules__list mb-2 mt-3'>
                <li className='rules__item'>
                  {' '}
                  <span>{/* <FontAwesomeIcon icon={faSquareCheck} /> */}</span>
                  <span className='pb-2 d-block font-weight-bold'>
                    Fichier accepté: <br />
                  </span>{' '}
                  &nbsp;JPEG/JPG/PNG (Taille max: 10 MB)
                </li>
                {/* <li className='rules__item rules__item--active'> */}
                <li className='rules__item rules__item'>
                  <span>{/* <FontAwesomeIcon icon={faSquareCheck} /> */}</span>
                  Le document doit être en bon état
                </li>
                {/* <li className='rules__item'>
                  <span><FontAwesomeIcon icon={faSquareCheck} /></span>{' '}
                  Face must be clear visible
                </li> */}
                <li className='rules__item'>
                  {' '}
                  <span>{/* <FontAwesomeIcon icon={faSquareCheck} /> */}</span>
                  Le document doit être une période de validité
                </li>
              </ul>

              <span className='uploadError'>{uploadValues && uploadError}</span>
            </div>
            {/* step three */}
            <div
              className='step'
              style={{ display: activeID == 3 ? 'block' : 'none' }}
            >
              <h4 className='text-center'>Informations personnelles</h4>
              <div className='form-group mb-4'>
                <h6>Nom et prénom</h6>
                <FormInput
                  type='text'
                  control={register('name', {
                    required: 'Le nom est requis',
                  })}
                  className='form-control'
                  placeholder='eg: Alex Hales'
                  error={errors.name}
                />
              </div>
              <div className='form-group mb-4'>
                <h6>Date de naissance</h6>
                <FormInput
                  type='date'
                  control={register('dob', {
                    required: 'La date de naissance est requise',
                  })}
                  className='form-control'
                  placeholder=''
                  error={errors.dob}
                />
              </div>
              <div className='form-group mb-4'>
                <h6>État/Province</h6>
                <FormInput
                  type='text'
                  control={register('state', {
                    required: 'Létat est requis',
                  })}
                  className='form-control'
                  placeholder='eg: Los Angeles'
                  error={errors.state}
                />
              </div>
              <div className='form-group mb-4'>
                <h6>Ligne d'adresse</h6>
                <FormInput
                  type='text'
                  control={register('streetAddress', {
                    required: 'Ladresse est requise',
                  })}
                  className='form-control'
                  placeholder='eg: 653 Jett Lane'
                  error={errors.streetAddress}
                />
              </div>
              <div className='form-group mb-4'>
                <h6>Nom de Ville</h6>
                <FormInput
                  type='text'
                  control={register('city', {
                    required: 'La ville est requise',
                  })}
                  className='form-control'
                  placeholder='eg: Los Angeles'
                  error={errors.city}
                />
              </div>
              <div className='form-group mb-4'>
                <h6>Postal Code</h6>
                <FormInput
                  type='text'
                  control={register('postal', {
                    required: 'Postal Code est requise',
                  })}
                  className='form-control'
                  placeholder='eg: 90017'
                  error={errors.postal}
                />
              </div>
            </div>
            {/* step four */}
            <div
              className='step'
              style={{ display: activeID == 4 ? 'block' : 'none' }}
            >
              <h4 className='text-center'>Succès</h4>
            </div>
            {/* start previous / next buttons */}
            <div className='form-footer d-flex justify-content-center'>
              {activeID === 1 && (
                <>
                  <PrimaryButton
                    variant='gradient'
                    color='info'
                    onClick={checkFieldSelectedHandler}
                    fullWidth
                    type='button'
                    id='nextBtn'
                  >
                    Suivant
                  </PrimaryButton>
                </>
              )}

              {activeID > 1 && activeID < 4 && (
                <button type='button' id='prevBtn' onClick={stepBackword}>
                  Précédent
                </button>
              )}

              {activeID === 2 ? (
                <>
                  {/* <button
                    type='button'
                    id='nextBtn'
                    onClick={checkFieldsHandler}
                  >
                    Next
                  </button> */}
                  <PrimaryButton
                    variant='gradient'
                    color='info'
                    onClick={checkFieldsHandler}
                    fullWidth
                    type='button'
                    id='nextBtn'
                  >
                    Suivant
                  </PrimaryButton>
                </>
              ) : (
                <>
                  {activeID < 3 ||
                    (activeID === 1 && (
                      <>
                        {/* <button
                          type='button'
                          id='nextBtn'
                          onClick={stepForword}
                        >
                          Next
                        </button> */}
                        <PrimaryButton
                          variant='gradient'
                          color='info'
                          onClick={stepForword}
                          fullWidth
                          type='button'
                          id='nextBtn'
                        >
                          Next
                        </PrimaryButton>
                      </>
                    ))}
                </>
              )}

              {activeID === 3 && (
                <PrimaryButton
                  variant='gradient'
                  color='info'
                  loading={loading}
                  type='submit'
                  id='submitBtn'
                  sx={{ border: 'none' }}
                >
                  Soumission
                </PrimaryButton>
              )}

              {activeID === 4 ? (
                <p className='success-main'>Formulaire soumis avec succès.</p>
              ) : (
                <></>
              )}
            </div>
            {/* end previous / next buttons */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default KycCard;
