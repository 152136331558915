import { Button } from '@mui/material';

const MyButton = ({ children, onSelect, endIcon, bgColor, mt }) => {
  return (
    <>
      <Button
        display='flex'
        justifyContent='center'
        alignItems='center'
        variant='contained'
        onClick={onSelect}
        outline='none'
        endIcon={endIcon}
        mt={mt}
        ml={2}
        style={{ margin: '3px', color: '#fff', backgroundColor: `${bgColor}` }}
      >
        {children}
      </Button>
    </>
  );
};

export default MyButton;
