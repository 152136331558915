export function email(value) {
  if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
    return "Email is invalid";
  }
}

export function formatCardNumber(numberStr) {
  return numberStr
    .replace(/\W/gi, "")
    .replace(/(.{4})/g, "$1 ")
    .substring(0, 19);
}

export function passwordRules() {
  return {
    minLength: {
      value: 6,
      message: "Password must be at least 6 characters",
    },
    pattern: {
      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
      message:
        "Password must contain at least one uppercase letter, one lowercase letter, and one number",
    },
  };
}