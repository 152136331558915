import SoftBox from '@components/SoftBox';
import { toMoment } from '@core/utils/time';

const ChartCard = ({ title, email, createdAt, points, background, color }) => {
  const joinDate = toMoment(createdAt).format('DD/MM/YYYY');
  return (
    <SoftBox
      sx={{
        background: `${background}`,
        color: { color },
        boxShadow: '2px 2px 5px #eee',
        borderRadius: '6px',
        margin: '3px 6px',
        display: 'flex',
        minWidth: '250px',
        minHeight: '70px',
        padding: '15px',
        fontSize: '14px',

        flexDirection: 'column',
        '&:hover': {
          backgroundColor: `${background}`,
          cursor: 'pointer',
          boxShadow: '1px 1px 15px #aaa',
        },
      }}
    >
      <p
        sx={{
          fontWeight: 'bold',
          fontSize: '18px',
          textTransform: 'uppercase',
        }}
      >
        {title}
      </p>
      {/* <p>Email:{email} </p>
      <p> Points: {points} </p> */}
      <p> Rejoint:{joinDate} </p>
    </SoftBox>
  );
};

export default ChartCard;
