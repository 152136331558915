import C1 from '../../assets/images/member-cards/1-student.jpeg';
import C2 from '../../assets/images/member-cards/2-volunteer.jpeg';
import C3 from '../../assets/images/member-cards/3-silver-business.jpeg';
import C4 from '../../assets/images/member-cards/4-gold-business.jpeg';
import C5 from '../../assets/images/member-cards/5-diamond-business.jpeg';
import C6 from '../../assets/images/member-cards/6-silver-donor.jpeg';
import C7 from '../../assets/images/member-cards/7-gold-donor.jpeg';
import C8 from '../../assets/images/member-cards/8-diamond-donor.jpeg';
import C9 from '../../assets/images/member-cards/9-platinum-donor.jpeg';
export const PLAN_CARDS = [
  {
    id: 1,
    title: 'ETUDIANT',
    price: 2,
    reward: 4,
    image: C1,
  },
  {
    id: 2,
    title: 'VOLONTAIRE',
    price: 3,
    reward: 6,
    image: C2,
  },
  {
    id: 3,
    title: 'CHEF D-ENTREPRISE SILVER',
    price: 5,
    reward: 10,
    image: C3,
  },
  {
    id: 4,
    title: 'CHEF D-ENTREPRISE GOLD',
    price: 10,
    reward: 20,
    image: C4,
  },
  {
    id: 5,
    title: 'CHEF D-ENTREPRISE DIAMOND',
    price: 20,
    reward: 40,
    image: C5,
  },
  {
    id: 6,
    title: 'DONATEUR SILVER',
    price: 300,
    reward: 600,
    image: C6,
  },
  {
    id: 7,
    title: 'DONATEUR GOLD',
    price: 800,
    reward: 1600,
    image: C7,
  },
  {
    id: 8,
    title: 'DONATEUR DIAMOND',
    price: 2000,
    reward: 4000,
    image: C8,
  },
  {
    id: 9,
    title: 'DONATEUR PLATINUM',
    price: 20000,
    reward: 40000,
    image: C9,
  },
];
