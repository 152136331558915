// import '../src/styles/sass/style.scss';

import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./mainStyle.css";

import { ReactReduxFirebaseProvider } from "react-redux-firebase";
// import { store } from './config/store.js';

import { rrfProps, store } from "@store/index";
// Soft UI Dashboard React Context Provider
import { SoftUIControllerProvider } from "@context";
import { useAuthStore } from "@store/AuthStore";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import { WagmiConfig, configureChains, createClient } from "wagmi";

// import { bsc, bscTestnet } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { environment } from "./lib";

const persistor = persistStore(store);

const defaultChains = environment.chains;
const { provider, webSocketProvider } = configureChains(defaultChains, [
  publicProvider(),
]);
const client = createClient({
  provider,
  webSocketProvider,
  autoConnect: true,
});

const AuthIsLoaded = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const authStore = useAuthStore();
  const authId = authStore.authId;
  const firebase = authStore.firebase;
  const user = authStore.selectItem(authId);

  firebase.auth().onAuthStateChanged(async (response) => {
    if (response?.uid && !user?.id) {
      const user = await authStore.getItem(response?.uid);
      // if (user?.verified == false) {
      //   await authStore.logout();
      // }
    }
    if (loading) {
      setLoading(false);
    }
  });

  if (loading) {
    return null;
  }

  return <>{children}</>;
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <WagmiConfig client={client}>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <PersistGate persistor={persistor}>
            <BrowserRouter>
              <SoftUIControllerProvider>
                <AuthIsLoaded>
                  <App />
                </AuthIsLoaded>
              </SoftUIControllerProvider>
            </BrowserRouter>
          </PersistGate>
        </ReactReduxFirebaseProvider>
      </Provider>
    </WagmiConfig>
  </React.StrictMode>
);

// serviceWorkerRegistration.register();
