import Grid from '@mui/material/Grid';

// Soft UI Dashboard React components
import SoftBox from '@components/SoftBox';

// Soft UI Dashboard React examples
import Footer from '@examples/Footer';

// Data

import OrgChart from './components/org-chart';

const TeamStructure = ({ referralTree }) => {
  return (
    <>
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <OrgChart referralTree={referralTree} />
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
      <Footer />
    </>
  );
};

export default TeamStructure;
