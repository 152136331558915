

import { useEffect, useState } from 'react';

// @mui material components
import AppBar from '@mui/material/AppBar';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

// Soft UI Dashboard React components
import SoftBox from '@components/SoftBox';
import SoftTypography from '@components/SoftTypography';

// Soft UI Dashboard React examples
import DashboardNavbar from '@examples/Navbars/DashboardNavbar';

// Soft UI Dashboard React icons
import Settings from '@examples/Icons/Settings';

// Soft UI Dashboard React base styles
import breakpoints from '@assets/theme/base/breakpoints';

// Images
import curved0 from '@assets/images/curved-images/curved0.jpg';
import { Verified } from '@mui/icons-material';
import { useAuthStore } from '@store/AuthStore';

function Header({ onClick }) {
  const authStore = useAuthStore();
  const user = authStore.user();
  const [tabsOrientation, setTabsOrientation] = useState('horizontal');
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal');
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener('resize', handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <SoftBox position='relative'>
      <DashboardNavbar absolute light />
      <SoftBox
        display='flex'
        alignItems='center'
        position='relative'
        minHeight='18.75rem'
        borderRadius='xl'
        sx={{
          backgroundImage: ({
            functions: { rgba, linearGradient },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${curved0})`,
          backgroundSize: 'cover',
          backgroundPosition: '50%',
          overflow: 'hidden',
        }}
      />
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
            rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: 'relative',
          mt: -6,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems='center'>
          <Grid item xs={12} md={6} lg={10} sx={{ ml: 'auto' }}>
            <SoftBox sx={{ display: 'flex', alignItems: 'center' }}>
              <SoftBox height='100%' mt={0.5} lineHeight={1}>
                <SoftTypography variant='h5' fontWeight='medium'>
                  {user?.name || 'Please Enter Name'}
                </SoftTypography>
                <SoftTypography
                  variant='button'
                  color='text'
                  fontWeight='medium'
                >
                  {user?.email}
                </SoftTypography>
              </SoftBox>
              {user?.status == 'approved' && (
                <SoftBox
                  height='100%'
                  mt={0.5}
                  lineHeight={1}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Verified
                    sx={{
                      width: '24px',
                      height: '24px',
                      fill: 'green',
                      marginLeft: '25px',
                    }}
                  />
                  <SoftTypography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 'bold',
                      marginLeft: '8px',
                    }}
                  >
                    Vérifié
                  </SoftTypography>
                </SoftBox>
              )}
              {(user?.status || 'rejected') == 'pending' && (
                <SoftBox
                  height='100%'
                  mt={0.6}
                  lineHeight={1}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Verified
                    sx={{
                      width: '24px',
                      height: '24px',
                      fill: 'yellow',
                      color: '#333',
                      marginLeft: '10px',
                    }}
                  />
                  <SoftTypography
                    sx={{
                      fontSize: { xs: '10px', md: '12px' },
                      fontWeight: 'bold',
                      marginLeft: '8px',
                    }}
                  >
                    En attente
                  </SoftTypography>
                </SoftBox>
              )}
              {(user?.status || 'rejected') == 'rejected' && (
                <SoftBox
                  height='100%'
                  mt={0.5}
                  lineHeight={1}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Verified
                    sx={{
                      width: '24px',
                      height: '24px',
                      color: 'red',
                      marginLeft: '25px',
                    }}
                  />
                  <SoftTypography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 'bold',
                      marginLeft: '8px',
                    }}
                  >
                    Non vérifié
                  </SoftTypography>
                </SoftBox>
              )}
            </SoftBox>
          </Grid>

          <Grid item xs={12} md={6} lg={2} sx={{ ml: 'auto' }}>
            <AppBar position='static'>
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ background: 'transparent' }}
              >
                {/* <Tab label="App" icon={<Cube />} />
                <Tab label="Message" icon={<Document />} /> */}
                {user?.status !== 'approved' && (
                  <Tab label='Modifier' icon={<Settings />} onClick={onClick} />
                )}
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </SoftBox>
  );
}

export default Header;
