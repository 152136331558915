import { BaseStore } from "@store/BaseStore";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";

class ReferralStore extends BaseStore {
  constructor(firestore, selector) {
    super("referrals", firestore, selector);
  }
}

export const useReferralStore = () => {
  return new ReferralStore(useFirestore(), useSelector);
};
