// @mui material components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard React components
import SoftBox from '@components/SoftBox';

// Soft UI Dashboard React examples
import LinkImg from '../../../../assets/images/link-2.svg';
// Billing page components
import SoftTypography from '@components/SoftTypography';
import { ContentCopy } from '@mui/icons-material';
import { Card } from '@mui/material';
import { useAuthStore } from '@store/AuthStore';
import { useState } from 'react';
import { position } from 'stylis';

const AffiliateTopBar = () => {
  const [copied, setCopied] = useState(false);
  const authStore = useAuthStore();
  const user = authStore.user();

  const affiliateUrl = `${window.location.origin}/sponsor/${user?.id}`;

  const handleCopy = () => {
    navigator.clipboard
      .writeText(affiliateUrl)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000); // Reset copied state after 2 seconds
      })
      .catch(error => {
        console.error('Error copying text: ', error);
      });
  };

  return (
    <>
      <Card>
        <SoftBox m={4}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              lg={8}
              sx={{
                padding: { xs: '0px !important', md: 'auto' },
              }}
            >
              <SoftBox display='flex' justifyContent='space-between'>
                <SoftBox
                  display='flex'
                  sx={{
                    width: '100%',
                    padding: { xs: '5px' },
                  }}
                >
                  <Box
                    sx={{
                      left: '2%',
                      top: '26%',
                      border: '1px solid #ededed',
                      width: '50px',
                      height: '50px',

                      display: { xs: 'none', md: 'flex' },
                      padding: '10px',
                      borderRadius: '100%',
                      fill: '#fff',
                      color: '#fff',
                      cursor: 'pointer',
                      fontSize: '30px',
                      cursor: 'pointer',
                      backgroundColor: '#eee',
                    }}
                  >
                    <img src={LinkImg} alt='link' />
                  </Box>
                  <SoftTypography
                    sx={{
                      marginLeft: { xs: '0px', md: '20px' },
                      fontWeight: '600',
                      fontSize: { xs: '20px', md: '30px' },
                    }}
                  >
                    Votre lien d'affiliation indépendant
                    <SoftTypography
                      display='flex'
                      sx={{
                        color: '#0842ac',
                        cursor: 'pointer',
                        ':hover': {
                          color: 'blue',
                        },
                        fontSize: { xs: '9px', md: '12px' },
                        letterSpacing: '0.5px',
                        position: 'relative',
                      }}
                    >
                      {affiliateUrl}
                      <SoftBox
                        sx={{
                          marginLeft: '10px',
                          fontSize: '16px',
                          marginTop: '-6px',
                        }}
                      >
                        <ContentCopy onClick={handleCopy} />

                        {copied && (
                          <SoftBox
                            sx={{
                              marginLeft: { xs: '-80px', md: '-10px' },
                              color: '#fff',
                              fontSize: '12px',
                              background: 'green',
                              width: '25%',
                              textAlign: 'center',
                              padding: '3px 6px',
                              borderRadius: '3px',
                              position: { xs: 'absolute', md: 'flex' },
                            }}
                          >
                            Lien copié
                          </SoftBox>
                        )}
                      </SoftBox>
                    </SoftTypography>
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </Card>
    </>
  );
};

export default AffiliateTopBar;
