

import { useEffect, useState } from "react";
// import './styles/sass/style.scss';

// react-router components
import { useLocation } from "react-router-dom";

// @mui material components
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

// Soft UI Dashboard React components

// Soft UI Dashboard React examples

// Soft UI Dashboard React themes
import theme from "@assets/theme";

// RTL plugins

// Soft UI Dashboard React routes

// Soft UI Dashboard React contexts
import {
    setMiniSidenav,
    setOpenConfigurator,
    useSoftUIController,
} from "@context/index";

// Images
import { WalletModal } from "@components/WalletModal";
import { useAuthStore } from "@store/AuthStore";

import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import AppWithRoutes from "./AppWithRoutes";

export default function App() {
  const authStore = useAuthStore();

  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } =
    controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // // Cache for the rtl
  // useMemo(() => {
  //   const cacheRtl = createCache({
  //     key: 'rtl',
  //     stylisPlugins: [rtlPlugin],
  //   });

  //   setRtlCache(cacheRtl);
  // }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <>
      <NotificationContainer />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppWithRoutes />
        <WalletModal />
      </ThemeProvider>
    </>
  );
}
