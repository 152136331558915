import curved9 from "@assets/images/signIn.png";
import { PrimaryButton } from "@components/Button";
import SoftBox from "@components/SoftBox";
import SoftTypography from "@components/SoftTypography";
import { FormInput } from "@core/forms";
import { ValidatorService } from "@core/services";
import { Hash } from "@core/utils/hash";
import { ArrowBack } from "@mui/icons-material";
import { useAuthStore } from "@store/AuthStore";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { useNavigate, useParams } from "react-router-dom";
import CoverLayout from "../components/CoverLayout";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const authStore = useAuthStore();
  const { code } = useParams();
  const user = authStore.selectFirstWithField("resetCode", code);

  const firebase = authStore.firebase;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm();

  useEffect(() => {
    if (code) {
      authStore.findByResetCode(code);
    }
  }, [code]);

  const validatePasswordConfirmation = (value) => {
    if (value !== watch("password")) {
      return "Passwords do not match";
    }
    return true;
  };

  const onSubmit = async (data) => {
    if (!user?.id) {
      setError("password", {
        type: "manual",
        message: "Invalid reset code",
      });
      return;
    }
    setLoading(true);

    await authStore.login(user.email, Hash.decrypt(user.password));
    const currentUser = firebase.auth().currentUser;

    if (currentUser?.email === user.email) {
      await currentUser?.updatePassword(data.password);
      await firebase.auth().signOut();

      await authStore.updateItem({
        id: user?.id,
        password: Hash.encrypt(data.password),
        resetCode: Hash.uniqueId(),
      });

      NotificationManager.success("Password reset successfully", "", 2000);

      navigate(`/authentication/sign-in`);

      setLoading(false);
    } else {
      setError("password", {
        type: "manual",
        message: "Invalid reset code",
      });
      setLoading(false);
    }

    setLoading(false);
  };

  return (
    <>
      <CoverLayout
        ArBack={<ArrowBack />}
        back="Go Back"
        title="Réinitialisez votre mot de passe"
        description="Veuillez entrer un nouveau mot de passe"
        image={curved9}
      >
        <SoftBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Password
              </SoftTypography>
            </SoftBox>
            <FormInput
              type="password"
              control={register("password", {
                required: "Password is required",
                ...ValidatorService.passwordRules(),
              })}
              className="form-control"
              placeholder="Password"
              error={errors.password}
            />
          </SoftBox>

          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Confirm Password
              </SoftTypography>
            </SoftBox>
            <FormInput
              type="password"
              control={register("passwordConfirm", {
                required: "Password confirmation is required",
                ...ValidatorService.passwordRules(),
                validate: validatePasswordConfirmation,
              })}
              className="form-control"
              placeholder="Password"
              error={errors.passwordConfirm}
            />
          </SoftBox>

          <SoftBox mt={4} mb={1}>
            <PrimaryButton
              loading={loading}
              variant="gradient"
              color="info"
              fullWidth
              type="submit"
            >
              Reset Password
            </PrimaryButton>
          </SoftBox>
        </SoftBox>
      </CoverLayout>
    </>
  );
};

export default ResetPassword;
