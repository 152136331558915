import { BaseStore } from "@store/BaseStore";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";

class UserStore extends BaseStore {
  constructor(firestore, selector) {
    super("users", firestore, selector);
  }

  getByEmail = async (email) => {
    return this.getItemsByQuery([["email", "==", email]]).then((res) => {
      return res.docs.length > 0
        ? { ...res.docs[0].data(), id: res.docs[0].id }
        : {};
    });
  };
}

export const useUserStore = () => {
  return new UserStore(useFirestore(), useSelector);
};
