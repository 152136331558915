import Grid from '@mui/material/Grid';

import './chartStyle.css';

import SoftBox from '@components/SoftBox';
import SoftTypography from '@components/SoftTypography';
import { Schema } from '@mui/icons-material';
import Card from '@mui/material/Card';
import { useAuthStore } from '@store/AuthStore';
import ChartCard from '../chart-card/ChartCard';

const currentDate = new Date().toLocaleDateString();
const TEAM1 = [
  {
    id: 't1',
    name: 'John',
    email: 'abc@abc.com',
    points: '2000',
    joinDate: currentDate,
  },
];
const TEAM2 = [
  {
    id: 't21',
    name: 'John',
    email: 'abc@abc.com',
    points: '2000',
    joinDate: new Date(),
  },
  {
    id: 't22',
    name: 'Mark',
    email: 'abc@abc.com',
    points: '5000',
    joinDate: new Date(),
  },
];
const TEAM3 = [
  {
    id: 't31',
    name: 'John',
    email: 'abc@abc.com',
    points: '2000',
    joinDate: new Date(),
  },
  {
    id: 't32',
    name: 'Mark',
    email: 'abc@abc.com',
    points: '5000',
    joinDate: new Date(),
  },
  {
    id: 't33',
    name: 'Mark',
    email: 'abc@abc.com',
    points: '5000',
    joinDate: new Date(),
  },
  {
    id: 't34',
    name: 'Mark',
    email: 'abc@abc.com',
    points: '5000',
  },
];

const makeItTree = (users, id) => {
  const tree = [];
  const children = users.filter(user => user.referralId === id);
  children.forEach(child => {
    const { id, name, email, phone, referralId } = child;
    tree.push({
      id,
      name,
      email,
      phone,
      referralId,
      children: makeItTree(users, id),
    });
  });
  return tree;
};

const OrgChart = ({ referralTree = [] }) => {
  const authStore = useAuthStore();
  const user = authStore.user();
  const levelOneUsers = referralTree;
  const levelTwoUsers = referralTree?.flatMap(user => user.children);

  return (
    <Card>
      <SoftBox mb={3} mt={1}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SoftBox p={2}>
              <SoftTypography
                variant='h3'
                sx={{
                  fontWeight: '600',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '1.875rem',
                  color: '#0842ac',
                }}
              >
                <Schema
                  sx={{
                    rotate: '-90deg',
                    width: '44px',
                    height: '44px',
                    padding: '10px',
                    fontSize: '10px',
                    color: '#555',
                    margin: '10px',
                    borderRadius: '100%',
                    backgroundColor: '#eee',
                    overflow: 'visible',
                  }}
                />
                Tableau D'équipe
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>

      <SoftBox mb={3} mt={2} sx={{ display: 'flex', flexDirection: 'row' }}>
        <Grid container spacing={1}>
          {/* Main Grid Wrap Start */}

          {/* Executive Level Starts */}

          <Grid
            container
            spacing={0}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '10px auto',
              marginLeft: '10px',
            }}
          >
            <Grid item xs={12} md={3}>
              <SoftBox
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  paddingLeft: { xs: '20px', md: '30px' },
                  paddingBottom: '20px',
                }}
              >
                <SoftBox
                  sx={{
                    width: '28px',
                    height: '28px',
                    background: '#0842ac',
                    borderRadius: '100%',
                  }}
                />
                <SoftTypography
                  sx={{
                    marginLeft: '10px',
                    fontSize: { xs: '13px', md: '15px' },
                    fontWeight: 'bold',
                    paddingTop: { xs: '5px', md: '2px' },
                  }}
                >
                  Exécutif
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <SoftBox
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingBottom: '10px',
                }}
              >
                <ChartCard
                  color={`#fff`}
                  background={'#0842ac'}
                  title={user.name}
                  points={user?.totalSpending || 0}
                  email={user.email}
                  createdAt={user.createdAt}
                />
              </SoftBox>
            </Grid>
          </Grid>

          {/* Executive Level Ends */}

          {/*  Level One Starts */}

          <Grid
            container
            spacing={0}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              margin: '10px auto',
              marginLeft: '10px',
            }}
          >
            <Grid item xs={12} md={3}>
              <SoftBox
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  paddingLeft: { xs: '20px', md: '30px' },
                  paddingBottom: '20px',
                }}
              >
                <SoftBox
                  sx={{
                    width: '28px',
                    height: '28px',
                    background: '#1565c0',
                    borderRadius: '100%',
                  }}
                />
                <SoftTypography
                  sx={{
                    marginLeft: '10px',
                    fontSize: { xs: '13px', md: '15px' },
                    fontWeight: 'bold',
                    paddingTop: { xs: '5px', md: '2px' },
                  }}
                >
                  Premier Niveau
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <SoftBox
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  overflow: 'auto',
                }}
              >
                {levelOneUsers.map((team, id) => (
                  <ChartCard
                    key={id}
                    background={'#1565c0'}
                    color={`#fff`}
                    title={team.name}
                    points={team?.totalSpending || 0}
                    email={team.email}
                    createdAt={team?.createdAt}
                  />
                ))}
              </SoftBox>
            </Grid>
          </Grid>

          {/*  Level One Ends */}

          {/*  Level Two Starts */}

          <Grid
            container
            spacing={0}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '10px auto',
              marginLeft: '10px',
            }}
          >
            <Grid item xm={12} md={3}>
              <SoftBox
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  paddingLeft: { xs: '20px', md: '30px' },
                  paddingBottom: '20px',
                }}
              >
                <SoftBox
                  sx={{
                    width: '28px',
                    height: '28px',
                    background: '#1e88e5',
                    borderRadius: '100%',
                  }}
                />
                <SoftTypography
                  sx={{
                    marginLeft: '10px',
                    fontSize: { xs: '13px', md: '15px' },
                    fontWeight: 'bold',
                    paddingTop: { xs: '5px', md: '2px' },
                  }}
                >
                  Niveau Deux
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <SoftBox
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  overflow: 'auto',
                }}
              >
                {levelTwoUsers.map((team, id) => (
                  <ChartCard
                    key={id}
                    background={'#1e88e5'}
                    color={'#fff'}
                    title={team.name}
                    points={team?.totalSpending || 0}
                    email={team.email}
                    createdAt={team.createdAt}
                  />
                ))}
              </SoftBox>
            </Grid>
          </Grid>

          {/*  Level Two Ends */}
        </Grid>
      </SoftBox>
    </Card>
  );
};

export default OrgChart;
