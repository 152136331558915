export const apiPost = async (endpoint, params, method = "GET") => {
  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  if (method !== "GET") {
    var urlencoded = new URLSearchParams();
    Object.keys(params).forEach((key) => {
      urlencoded.append(key, params[key]);
    });
  }
  const getOptions = {
    method,
    // headers,
    redirect: "follow",
  };
  const postOptions = {
    ...getOptions,
    body: urlencoded,
  };

  let url = `${endpoint}`;

  try {
    const result = await fetch(
      `${url}`,
      method === "GET" ? getOptions : postOptions
    );
    const data = await result.json();
    return data;
  } catch (error) {
    console.log("api error", error);
    return { message: error };
  }
};
