

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard React components
import SoftBox from '@components/SoftBox';
import SoftTypography from '@components/SoftTypography';

// Soft UI Dashboard React examples
import PageLayout from '@examples/LayoutContainers/PageLayout';

// Authentication layout components
import Footer from '@layouts/authentication/components/Footer';

function BasicLayout({ title, description, image, children }) {
  return (
    <PageLayout>
      {/* <DefaultNavbar
        action={{
          type: 'external',
          route: 'https://creative-tim.com/product/soft-ui-dashboard-react',
          label: 'free download',
        }}
        transparent
        light
      /> */}
      <SoftBox
        width='100%'
        minHeight='50vh'
        mx={0}
        pt={8}
        pb={28}
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Grid
          container
          spacing={3}
          justifyContent='center'
          sx={{ textAlign: 'center' }}
        >
          <Grid item xs={10} lg={4}>
            <SoftBox mt={6} mb={1}>
              <SoftTypography variant='h1' color='white' fontWeight='bold'>
                {title}
              </SoftTypography>
            </SoftBox>
            <SoftBox mb={2}>
              <SoftTypography
                variant='body2'
                color='white'
                fontWeight='regular'
              >
                {description}
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox
        mt={{ xs: -26, lg: -24 }}
        px={1}
        width='calc(100% - 8rem)'
        mx='auto'
      >
        <Grid container spacing={1} justifyContent='center'>
          <Grid item xs={11} sm={9} md={6}>
            {children}
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </PageLayout>
  );
}

// Setting default values for the props of BasicLayout
BasicLayout.defaultProps = {
  title: '',
  description: '',
};

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
